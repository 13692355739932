<template>

<div class="cell small-12 large-10 ">
 

       
  
        <template v-if="bookmakeroffers.length>0">


            <template v-if="ss == 'All'">
            <h1 class="qmajorheader" >{{langConv("New Customer Offers") }}</h1>
            <h2 class="cell qsubheader">{{langConv("All new customer offer available at your location")}}</h2>
            </template>

            <div v-for="(bo,i) in bookmakeroffers" :key="i">
                
          
                <div style="padding:5px" >

                    <div class="grid-x" style="text-align:center" v-if="ss == 'brief'">
                        <div class="cell small-2"><a :href="'/bookmakerloader.html?bkm=' + bo.D + '&country=' + $store.state.countryname" target="_blank"><span v-bind:class="'b' + bo.D"></span></a></div>
                        <div class="cell small-10 qmediumboldfont text-center" v-html="bo.bantitle"></div>
                        
                        <div class="cell qsmallfontplus text-center" style="padding:2px 0px 4px 0px" v-html="bo.shortd" v-if="bo.shortd != bo.bantitle"></div>
            
                        <div class="grid-x qxsupersmallfont" style="margin-top:5px">
                            <template v-if="bo.freegamesrating">
                                <span class="cell auto"><span :class="[{ tick: true,qsmallcircularpop: true }]" ></span><span style="display:block; margin-top: 7px">{{ langConv('Free games')}}</span> </span>
                             </template>
                             <template v-if="bo.cashoutbets">
                                <span class="cell auto"><span :class="[{ tick: true,qsmallcircularpop: true }]"></span><span style="display:block; margin-top: 7px">{{ langConv('Cash out bets option')}}</span></span>
                             </template>
                             <template v-if="bo.upto100cashout">
                                <span class="cell auto"><span :class="[{ tick: true,qsmallcircularpop: true }]" v-if="bo.freegamesrating"></span><span style="display:block; margin-top: 7px">{{ langConv('100% cashout option')}}</span></span>
                             </template>
                             <template v-if="bo.firstclasssupport">
                                <span class="cell auto"><span :class="[{ tick: true,qsmallcircularpop: true }]" v-if="bo.freegamesrating"></span><span style="display:block; margin-top: 7px">{{ langConv('Industry best support')}}</span></span>
                             </template>
                             </div>  


                        <div class="cell" style="padding-top:5px">
                            <div class="grid-x">
                                <div class="cell auto"></div>
                                <div class="cell small-4"><actionbutton :bookmaker="bo.D"></actionbutton></div>
                                <div class="cell auto"></div>
                            </div>
                        </div>
                                               
                        <div v-if="elementvisible[i] && bo.tandcs.length > 0" class="cell" target="_blank"><a :href="bo.tandcs">{{ langConv('T and Cs apply') }}</a></div> 
                        <hr style="margin:10px">
                    </div>
                   
                    <template v-if="ss == 'All'">
 
                        <div class="grid-x grid-margin-x" style="text-align:center">
                            
                            <div class="cell" ><a :href="'/bookmakerloader.html?bkm=' + bo.D + '&country=' + $store.state.countryname"><span v-bind:class="'m' + bo.D"></span></a></div>
                            <div class="cell qlargerfont" style="line-height:40px" v-html="bo.bantitle"></div>
                        
                        </div>
                        <div style="text-align:center">
                            <div class="qmediumfont qdarkgreylink" style="padding-bottom:10px" v-html="bo.shortd" v-if="bo.shortd != bo.bantitle"></div>
                            <div class="grid-x qmediumfont">
                            <template v-if="bo.freegamesrating">
                                <span class="cell auto">Free games <span :class="[{ tick: true,qsmallcircularpop: true }]"></span></span>
                             </template>
                             <template v-if="bo.cashoutbets">
                                <span class="cell auto">Cash out bets option<span :class="[{ tick: true,qsmallcircularpop: true }]"></span></span>
                             </template>
                             <template v-if="bo.upto100cashout">
                                <span class="cell auto">100% cashout option<span :class="[{ tick: true,qsmallcircularpop: true }]" v-if="bo.freegamesrating"></span></span>
                             </template>
                             <template v-if="bo.firstclasssupport">
                                <span class="cell auto">Industry best support<span :class="[{ tick: true,qsmallcircularpop: true }]" v-if="bo.freegamesrating"></span></span>
                             </template>
                             </div>  
                            <div class="qsmallfont" v-if="bo.tandcs.length > 0" target="_blank">{{ langConv('T and Cs apply') }}</div>
                            <div style="max-width:100px;margin:auto"><a :href="'/bookmakerloader.html?bkm=' + bo.D + '&country=' + $store.state.countryname" target="_blank" ><actionbutton :bookmaker="bo.D"></actionbutton></a></div> 
                        </div>
                        <hr style="margin:10px">
                    
                    </template>

                </div>

            </div>

        </template>

        </div>

</template>

<script>

    //note: global gv not available in markup so convert to option as a local data const
    import axios from 'axios'
    import actionbutton from '/src/components/components/actionbutton.vue'


    
    
    export default {
    name: "offers",
    data: function () {
        return {
            bookmakeroffers: [],
            options: this.$screensize,
            ss: this.param1,
            elementvisible: [],
            smallbuttontext: "Show"
        };
    },
    components: {
        actionbutton
    },
    mounted() {
        this.availableoffers();
    },
    props: {
        param1: { type: String, default: "All" }
    },
    methods: {
        toggle: function (itemno) {
            if (this.elementvisible[itemno] != true) {
                this.$set(this.elementvisible, itemno, true);
                this.smallbuttontext = "Close";
            }
            else {
                this.$set(this.elementvisible, itemno, false);
                this.smallbuttontext = "Show";
            }
        },
        availableoffers: function () {

            var promotype

            if (this.param1 == 'Full') {promotype = '2'} else {promotype == '1'}  
        
            axios.get(process.env.VUE_APP_WEBAPI + "AdvertService.svc/PromoOffersLongList", {
           
                params: {
                    bannertypeID: promotype,
                    country: this.$store.state.countryname,
                    noitems: 20
                }
            }).then(response => {
                
                this.bookmakeroffers = JSON.parse(response.data);
            })
                .catch(error => { console.log("failed to load adds"); });
        },
    },
};



</script>


<style scoped>
    @import '../../style/bkmmedium.css'
</style>